<template>
  <div class="row_contain" style="padding-top: 46px">
    <van-nav-bar
      :fixed="fixed"
      title="我的地址"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-empty
      image="error"
      description="暂无收货地址"
      v-if="list.length == 0"
    />

    <van-address-list
      v:else
      v-model="chosenAddressId"
      :list="list"
      default-tag-text="默认"
      @add="onAdd"
      @edit="onEdit"
      @select="onSelect"
    />
  </div>
</template>

<script>

export default {
  data () {
    return {
      fixed: true,
      chosenAddressId: '',
      list: []
    };
  },
  created () {
    this.load();
  },
  methods: {
    //address/get_list
    async load () {
      this.$Toast.loading({ forbidClick: true });
      let res = await this.$http.get('address/get_list', {
        params: {
          user_id: this.$store.state.login.userInfo.user_id
        }
      })

      if (res.data.data.length > 0) {

        for (var i in res.data.data) {
          this.list.push({ id: res.data.data[i].address_id, address: res.data.data[i].address_info, name: res.data.data[i].name, tel: res.data.data[i].phone, isDefault: res.data.data[i].is_default == '1' ? true : false });
          if (res.data.data[i].is_default == '1') {
            this.chosenAddressId = res.data.data[i].address_id
          }
        }

      }
      this.$Toast.clear();
    },

    onClickLeft () {
      this.$router.back()
    },
    async onSelect (item) {
      // Toast('地址:' + item); 
      this.$Toast.loading({ forbidClick: true });
      let value = this.$qs.stringify({
        address_id: item.id,

      })
      let res = await this.$http.post('address/default_address', value);
      if (res.data.error_code == 0) {
        this.$Toast.clear();
        this.list = [];
        this.load();
        this.$Toast('修改成功');

      }
    },
    onAdd () {

      this.$router.push('editAddress/1/0')
    },
    onEdit (item) {

      this.$router.push('editAddress/2/' + item.id)
    },
  },
}
</script>

<style>
.van-address-list__bottom {
  bottom: 60px;
  background: none;
}
</style>